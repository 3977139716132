import React from 'react';
import PropTypes from 'prop-types';
import marked from 'marked';
import { ReactSVG } from 'react-svg';
import OurDetailsStyles from './OurDetails.styled';
import Container from '../Shared/Container';
import { Element } from 'react-scroll';
import emailSvg from './images/email.svg';

function OurDetails({
  contactInfo
}) {


  const title = contactInfo ? contactInfo.contactHeader : "";
  const text = contactInfo ? contactInfo.contactIntro : "";
  const backgroundColor = contactInfo ? contactInfo.contactBackgroundColor : "";
  const backgroundImage = contactInfo && contactInfo.contactImage && contactInfo.contactImage.file.url;

  let locations = [];

  if (contactInfo && contactInfo.primaryContactLabel){
    locations.push(
      {
        name: contactInfo.primaryContactLabel,
        phone: contactInfo.primaryContactPhone,
        email: contactInfo.primaryContactEmail
      }
    );
  }

  if (contactInfo && contactInfo.secondaryContactLabel)
  {
    locations.push(
      {
        name: contactInfo.secondaryContactLabel,
        phone: contactInfo.secondaryContactPhone,
        email: contactInfo.secondaryContactEmail
      }
    );
  }


  marked.setOptions({
    pedantic: false,
    gfm: true,
    tables: true,
    breaks: false,
    sanitize: false,
    smartLists: true,
    smartypants: false,
    xhtml: false,
    closeHtmlTags: true
  });

  const backgroundHexColor = (backgroundColor.indexOf("#") >= 0) ? `#${backgroundColor.split("#")[1]}` : null;

  const trimmedText = text ? text.trim() : "";
  
  return (
    <OurDetailsStyles
      backgroundColor={backgroundHexColor && backgroundHexColor} 
      backgroundImage={backgroundImage}
      backgroundImageAlign="right"
      isPreFooterCTA={true}

    >
      <Element name="details">
        <Container>
          <div className="inner">
            <h1 className="title">{title}</h1>

            {
              trimmedText !== "" ? (
                <div className="text"
                  dangerouslySetInnerHTML={{ __html: marked(trimmedText) }}
                />  
                ) 
              : null
            }

            <div className="locations">
              {locations.map((location, idx) => (
                <div key={`location-${idx}`} className="location-info">
                  <div className="location-name">{location.name}</div>
                  <div className="location-card">

                    {location.phone && (
                    <div className="phone">
                      <a
                        className={(location.name.toLowerCase().indexOf("freight") > -1) ? "gtm-tel-freight" : "gtm-tel-careers"}
                        href={`tel:${location.phone}`}
                      >
                        {location.phone}
                      </a>
                    </div>

                    )}
                    {location.email && (
                      <div className="email">
                        <ReactSVG wrapper="span" className="email-icon" src={emailSvg} alt="E-mail" />
                        <a
                          className={(location.name.toLowerCase().indexOf("freight") > -1) ? "gtm-email-freight" : "gtm-email-careers"}
                          href={`mailto:${location.email}`}
                        >
                          {location.email}
                        </a>
                      </div>
                    )}
                  </div>
                </div>
              ))}
            </div>
          </div>
        </Container>
      </Element>
    </OurDetailsStyles>
  );
}

OurDetails.propTypes = {
  title: PropTypes.string,
  text: PropTypes.string,
  locations: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      phone: PropTypes.string,
      email: PropTypes.string,
    })
  ),
};

export default OurDetails;

import React from 'react';
import PropTypes from 'prop-types';
import { Element } from 'react-scroll';
import OurStoryStyles from './OurStory.styled';
import ScrollLink from '../Shared/ScrollLink';
import Container from '../Shared/Container';
import marked from 'marked';


function OurStory({
  title,
  text,
  buttonText,
  buttonLink,
}) {

  marked.setOptions({
    pedantic: false,
    gfm: true,
    tables: true,
    breaks: false,
    sanitize: false,
    smartLists: true,
    smartypants: false,
    xhtml: false,
    closeHtmlTags: true
  });

  var trimmedText = (typeof(text) === "string") ? text.trim() : "";

  return (
    <OurStoryStyles>
     <Element name="story">
        <Container>
          <div className="inner">
            <h2 className="title">{title}</h2>
            <div className="text"
              dangerouslySetInnerHTML={{ __html: marked(trimmedText) }}
            />            
            {
              (buttonLink) ? 
                (
                  <ScrollLink 
                    className="cta-button gtm-contact-us" 
                    to={buttonLink}
                    useScroll={buttonLink.indexOf("http") === -1}
                    target={buttonLink.indexOf("http") === -1 ? "_self" : "_blank"}
                    tabIndex="0"
                  >
                    {buttonText}
                  </ScrollLink>
                )
                : null
            }
            <hr className="divider" />
          </div>
        </Container>
      </Element>
    </OurStoryStyles>
  );
}

OurStory.propTypes = {
  title: PropTypes.string,
  text: PropTypes.string,
  buttonText: PropTypes.string,
  buttonLink: PropTypes.string,
};

export default OurStory;

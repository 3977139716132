import Colors from 'pubweb-smokey/dist/colors';

const localStorage = {};

export const getLocalStorage = (key) => {

    let value;
    if(typeof(window) === "undefined"){
        value = localStorage[key];
    }
    else {
        value = window.localStorage[key];
    }

    try{
        value = JSON.parse(value);
    }
    catch {

    }

    return value;
}

export const setLocalStorage = (key, value) => {
    if(typeof(value) !== "string"){
        value = JSON.stringify(value);
    }

    if(typeof(window) === "undefined"){
        localStorage[key] = value;
        return;
    }

    return window.localStorage.setItem(key, value);
}

const sessionStorage = {};

export const getSessionStorage = (key) => {

    let value;
    if(typeof(window) === "undefined"){
        value = sessionStorage[key];
    }
    else {
        value = window.sessionStorage[key];
    }

    try{
        value = JSON.parse(value);
    }
    catch {

    }

    return value;
}

export const setSessionStorage = (key, value) => {
    if(typeof(value) !== "string"){
        value = JSON.stringify(value);
    }

    if(typeof(window) === "undefined"){
        sessionStorage[key] = value;
        return;
    }

    return window.sessionStorage.setItem(key, value);
}

export const getCityStateZipFromLocationString = (locationString) => {
    if (locationString) {
        const locationStringParts = locationString.split('--');
        if (locationStringParts.length > 2) {
            return {
                city: locationStringParts[0],
                state: locationStringParts[1],
                postalCode: locationStringParts[2],
            }
        }
    }

    return null;
}

const hexColorRegex = /#[\da-f]{6,8}/gmi;

export const extractColors = (stringValue) => {
    if(!stringValue){
        return [];
    }
    return stringValue.match(hexColorRegex) || [];
}

export const getCookie = (name) => {
    return document.cookie.split(";").map(cookie => {
        const cookieKvp = cookie.split("=");
        return {
            name: cookieKvp[0].trim(),
            value: cookieKvp[1]
        }
    }).filter(cookie => cookie.name == name)[0];
}

export const getGAClientId = () => {
    try {
        const trackingCookie = getCookie("_ga");
        if(trackingCookie){
            const trackingCookieSplit = trackingCookie.value.split(".");
            return trackingCookieSplit[trackingCookieSplit.length - 2] + "." + trackingCookieSplit[trackingCookieSplit.length - 1];
        }
    } catch (e) {
        return '';
    }
};

export const getKenshooId = () => {
    try{
        const urlParams = new URLSearchParams(window.location.search);
        const kenshooId = urlParams.get('kenshooid');
        return kenshooId;
    } catch(e) {
        return '';
    }
}

export const isSlugInternal = (slug) => {
    slug = (slug || "").toLowerCase();

    if(slug[0] !== "/"){
        slug = "/" + slug;
    }

    for(let i = 0; i < externalSlugs.length; i++){
        if(slug.indexOf(externalSlugs[i]) === 0){
            return false;
        }
    }

    return true;
}


export const getSlug = (slug) => {
    slug = (slug || "").toLowerCase();
    slug = slug.replace("//", ":");

    const slashIndex = slug.indexOf("/");
    return slug.substring(slashIndex);
}

export const scrollToElement = (element, yOffset = 0) => {
    if(element){
        window.scrollTo({top: window.pageYOffset + element.getBoundingClientRect().top + yOffset, behavior: "smooth"});
    }
}

  
export const convertButtonColor = (hexCode) => {
let color;

// Check these or the toLowerCase will give an exception.
if (!hexCode || !hexCode[0] || !hexCode[1]) {
    // What to do if no color defined? Could leave color as 'undefined' as well.
    return Colors.primary.claytonBlue;
}

switch(( hexCode[0].toLowerCase() || "#ffffff")) {
    case "#0075c9":
        color = Colors.primary.claytonBlue;
        break;
    case "#352269":
        color = Colors.secondary.summerNight;
        break;
    case "#00b0ac":
        color = Colors.secondary.kiddiePool;
        break;
    case "#ef4b5d":
        color = Colors.accent.ladyBug;
        break;
    case "#004b8d":
        color = Colors.accent.darkBlue;
        break;
    case "#b21f28":
        color = Colors.accent.barnDoor;
        break;
    case "#4f9e44":
        color = Colors.accent.freshGrass;
        break;

    case "#00000000":
        color = Colors.secondary.transparent;
        break;
    default:
        color = Colors.primary.claytonBlue;
    }

    if(hexCode[0].toLowerCase() === "#ffffff"){
    switch(hexCode[1].toLowerCase()) {
        case "#0075c9":
            color = Colors.primary.claytonBlue;
            break;
        case "#352269":
            color = Colors.secondary.summerNight;
            break;
        case "#00b0ac":
            color = Colors.secondary.kiddiePool;
            break;
        case "#ef4b5d":
            color = Colors.accent.ladyBug;
            break;
        case "#004b8d":
            color = Colors.accent.darkBlue;
            break;
        case "#b21f28":
            color = Colors.accent.barnDoor;
            break;
        case "#00000000":
            color = Colors.secondary.transparent;
            break;
        default:
            color = Colors.primary.claytonBlue;
        }
    }
    return color;
}

export const determineTextColor = (backgroundColorHexCode) => {
let color;
switch(backgroundColorHexCode[0]) {
    case "#00000000":
        color = Colors.secondary.transparent;
        break;
    case "#f8f8f8":
        color = Colors.primary.black.standard;
        break;
    case "#e5f5ff":
        color = Colors.primary.black.standard;
        break;
    case "#E5F5FF":
        color = Colors.primary.black.standard;
        break;
    case "#ffffff":
        color = Colors.primary.black.standard;
        break;
    case undefined: 
        color = Colors.primary.black.standard;
        break;
    default:
        color = "#ffffff";
}
return color;
}

export const reverseColors = (hexCode) => {
//returns true or false
//if true, reverse colors by passing in {true} to the buttons onDarkBg prop

// Check these or the toLowerCase will give an exception.
if (!hexCode || !hexCode[0] || !hexCode[1]) {
    return false;
}

//if the button is transparent (#00000000), onDarkBG is always false
if(hexCode[0] === "#00000000" || hexCode[1] === "#00000000") {
    return false;
}
//if color[0] is #ffffff, onDarkBG is true (i.e. white button, with colored text)
if(hexCode[0].toLowerCase() === "#ffffff") {
    return true;
}

return false;
}
  

import styled from 'styled-components';
import * as variables from 'pubweb-smokey/dist/components/GridSystem/_vars_widths.js';

export const WebsiteBannerStyles = styled.div`
    /* Limit the max space for the banners and add a scroll bar as needed */
    max-height: 300px;
    overflow-y: auto;

    @media only screen and (min-width: ${variables.tablet_breakpoint}px){

    }
    @media only screen and (min-width: ${variables.desktop_breakpoint}px){

    }
`;
import xHttp from '../utils/xHttp';
import { getBaseAwsApiUrl } from '../utils/config';

export const getDynamicPageContent = (key) => {
    let url = `${getBaseAwsApiUrl()}v1/contentful/dpc/${key}`;
    return xHttp.get(url);
}

export const getFullPageControlContent = (key) => {
    let url = `${getBaseAwsApiUrl()}v1/contentful/fcc/${key}`;
    return xHttp.get(url);
}

export const getWebsiteAreaContent = (key) => {
    let url = `${getBaseAwsApiUrl()}v1/contentful/wac/${key}`;
    return xHttp.get(url);
}

export const getCuratedCollection = (slug) => {
    let url = `${getBaseAwsApiUrl()}v1/curated-collections/${slug}`;
    return xHttp.get(url);
}

export const getWebsiteBanner = (key) => {
    let url = `${getBaseAwsApiUrl()}v1/contentful/banner/${key}`;
    return xHttp.get(url);
}

export const getAlertPage = (key) => {
    // Added the 'key' as a query string because it didn't seem to like it as part of the route.
    let url = `${getBaseAwsApiUrl()}v1/contentful/alert/?key=${key}`;
    return xHttp.get(url);
}

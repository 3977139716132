import React from 'react';

import CTA from '../Shared/CTA/CTA';
import { extractColors } from '../../utils/utils';

import PreFooterCTAStyles from './PreFooterCTA.styled';

// Note: September 2021
// This is using the CTA component that was modified to allow buttons with anchor tags (originally for Clayton Connect and possibly other Full Content Control pages).
// If the functionality of the CTA normally used in clayton-chcom library is required, please use it instead.
// Other Full Content Control pages have not been defined yet, so it is possible those pages may require additional logic to determine if a url should open on
// a new page or not. This may require different functions (e.g. getUrlTarget) to determine if the new link is internal or external in order to evaluate the 'target'.
const PreFooterCTA = ({preFooterCTA}) => {
    return (
        <PreFooterCTAStyles>
            {preFooterCTA ?
                <div className="prefooter-cta-container" key={preFooterCTA.sys.id}>
                    <CTA
                        buttonLocation={preFooterCTA.ctaButtonLocation}
                        buttonColors={extractColors(preFooterCTA.buttonAndTextColors)}
                        secondButtonColors={preFooterCTA.secondButtonColor}
                        backgroundColor={extractColors(preFooterCTA.ctaBackgroundColor)}
                        image={(preFooterCTA.ctaImage !== null) ? preFooterCTA.ctaImage.file.url : null}
                        imageAlign={preFooterCTA.ctaImageLocation}
                        contentAlign={preFooterCTA.alignCtaContent}
                        customCategory={null} //{preFooterCTA.ctaCustomCategory}
                        tag={null} //{preFooterCTA.ctaTag}
                        headingText={preFooterCTA.ctaHeader}
                        subHeadingText={null} //{preFooterCTA.ctaSubHeader}
                        ctaText={preFooterCTA.ctaIntro}
                        buttonText={preFooterCTA.firstCtaButtonText}
                        buttonUrl={preFooterCTA.firstCtaButtonUrl}
                        buttonAutomationId="prefooter-cta-button-1"
                        // Set this false so the CTA code will evaluate the value for 'target' using getUrlTarget.
                        buttonOpensToNewTab={true}
                        button2Text={preFooterCTA.secondCtaButtonText}
                        button2Url={preFooterCTA.secondCtaButtonUrl}
                        button2OpensToNewTab={true}
                        button2AutomationId="prefooter-cta-button-2"
                        // This does some different formatting for a prefooter CTA
                        isPreFooterCTA={true}
                        // The figma was showing left aligned image (for mobile), but we can remove this if we decide to use contentful value.
                        //imageAlign="Left Side"
                    />
                </div>
                : null
            }
        </PreFooterCTAStyles>
    );
}

export default PreFooterCTA;
import styled from 'styled-components';
import Colors from 'pubweb-smokey/dist/colors';
import * as variables from 'pubweb-smokey/dist/components/GridSystem/_vars_widths.js';

export const TextSectionStyles = styled.div`
  background-color: ${props => props.backgroundColor};
  margin: 0 auto;
  padding-bottom: 50px;
`;

const OurServicesStyles = styled.div`
  margin: 0 auto;
  text-align: center;

  .inner {    
    padding: 0 24px;

    .title {
      text-align: center;
      margin: 0 0 24px;
      font-size: 48px;
      font-weight: 800;
      color: ${Colors.primary.black.standard};
    }
    .text {
      color: #000;
      padding-bottom: 20px;

      p{
        font-family: "acumin-pro", sans-serif;
        font-weight: 400;
      }
      a{
        cursor: pointer;
      }

    }

    .intro-text p{
      font-size: 18px;
    }


    .split-content {
      display: block;
      align-items: center;
      justify-content: space-between;
      &.right-split {
        flex-direction: row-reverse;
      }

      .image-block {
        flex: 0 0 calc(50% - 30px);
        img {
          width: 100%;
          box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1),
            0 4px 6px -2px rgba(0, 0, 0, 0.05);
          vertical-align: middle;

        }
      }
      .text-block {
        flex: 0 0 calc(50% - 48px);

        .content-title {
          text-align: left;
          margin: 16px 0 24px;
          font-family: 'acumin-pro-semi-condensed', sans-serif;
          font-size: 34px;
          font-weight: 700;
          color: ${Colors.primary.black.standard};
        }
        .text{
          text-align: left;
        }
        .content-text {
          margin: 0 0 32px;
          font-size: 18px;
          line-height: 1.5;
          color: #000;
        }
      }
    }

    .full-content{
        text-align: center;      
        
        .content-title{
          font-size: 34px;
          font-weight: 700;
        }

        .text{
          h3{
            font-size: 18px;
            font-weight: 500;
            color: ${Colors.accent.grey1.standard};
          }
          h1{
            font-size: 34px;
            font-weight: 700;
            color: ${Colors.primary.black.standard};
          }
        }
    }

    .cta-section {

      .section-link-block{
        padding-bottom: 20px;
      }

      .section-link, .section-link:visited{
        padding-bottom: 20px;
        font-family: "acumin-pro", sans-serif;
        color: ${Colors.primary.claytonBlue.standard};
        text-decoration: none;
        cusor: pointer;

        ::after{
          content: "►";
          padding-left: 5px;
          font-family: sans-serif;
          font-size: 12px;
        }
      }


    }
  }
  @media only screen and (min-width: ${variables.desktop_breakpoint}px){
    .inner{
      .text{
        padding-bottom: 50px;
      }
      .split-content{
        display: flex;
      }
      .full-content{
        .content-title{
          font-size: 34px;
          font-weight: 700;
        }
        .text{
          h3{
            font-size: 26px;
          }
          h1{
            font-size: 48px;
          }
        }
      }
    }
  }
`;

export default OurServicesStyles;

import styled from 'styled-components';
import * as variables from 'pubweb-smokey/dist/components/GridSystem/_vars_widths.js';


const OurDetailsStyles = styled.div`

    background-color: ${props => props.backgroundColor ? props.backgroundColor : "#fff"};
    background-image: url(${props => props.backgroundImage ? props.backgroundImage : "none"});
    background-position-x: ${props => props.backgroundImageAlign ? props.backgroundImageAlign : "right"};
    background-position-y: ${props => props.backgroundImageAlign === "center" || props.isPreFooterCTA ? "top" : "center"};
    background-size: ${props => props.backgroundImageAlign === "center" || props.isPreFooterCTA ? "cover" : "contain"};
    background-repeat: no-repeat;
    padding: 25px;
    color: #fff;
    font-family: 'acumin-pro', sans-serif;

    .title{
      font-size: 48px;
      line-height: 61px;
      font-weight: 700;
      color: #fff;
    }

    .text{
      color: #fff;
      padding-bottom: 75px;
      p{
        color: #fff;
        font-family: "acumin-pro", sans-serif;
        font-weight: 400;
        font-size: 18px;
      }
    }

    .location-name{
      width: 195px;
      font-family: 'source-sans-pro', sans-serif;
      font-weight: 700;
      font-size: 12px;
      line-height: 12px;
      text-transform: uppercase;
    }

    .location-card{
      font-family: 'acumin-pro', sans-serif;
      padding-bottom: 75px;
      

      .phone{
        font-weight: 700;
        font-size: 34px;
        line-height: 40px;
        padding: 6px 0;
      }

      .email{
        line-height: 26px;
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        padding-top: 5px;

        .email-icon{
          position: relative;
          padding-right: 5px;
          top: 8px;

          svg{
            width: 30px;
          }
        }
      }
    }

    a, a:visited, a:hover{
      text-decoration: none;
      color: #fff;
    }

    @media only screen and (min-width: 320px){
      
      .location-card{
        .phone{
          font-size: 40px;
        }
        .email{
          font-size: 14px;
        }
      }
    }

    @media only screen and (min-width: 365px){
      
      .location-card{
        .phone{
          font-size: 40px;
        }
        .email{
          font-size: 18px;
          .email-icon{
            padding-right: 10px;
          }
        }
      }
    }

    @media only screen and (min-width: ${variables.desktop_breakpoint}px){

      .inner{
        width: 85%;
        margin: 0 auto;
      }

      .location-info:nth-child(2){
        margin-left: 55px;
      }

      .locations{
        display: flex;
        justify-content: flex-start;

      }

      .location-name{
        width: auto;
      }

      .location-card{
        .phone{
          font-size: 40px;
        }
        .email{
          font-size: 16px;
        }
      }
    }

    @media only screen and (min-width: 810px){
      
      .location-info:nth-child(2){
        margin-left: 75px;
      }

      .location-card{
        .phone{
          font-size: 40px;
        }
        .email{
          font-size: 16px;
        }
      }
    }

    @media only screen and (min-width: 912px){

            .location-card{
              .phone{
                font-size: 48px; 
              }
              .email{
                font-size: 18px;
              }
            }
    }

    @media only screen and (min-width: 975px){
      .location-info:nth-child(2){
              margin-left: 125px;
      }
        
    }


`;

export default OurDetailsStyles;

import styled from 'styled-components';
import * as variables from 'pubweb-smokey/dist/components/GridSystem/_vars_widths.js';

const PreFooterCTAStyles = styled.div`
    
    .prefooter-cta-container {
        > div {
            /* Center the 'cta-inside' content in the CTA div */
            display: flex;
            align-items: center;
            justify-content: center;

            /* Min height was set to match Figma height. Remove if we don't need this. */
            min-height: 390px;
        }
        .cta-buttons {
            padding-top: 20px;
        }
    }

    @media screen and (min-width: ${variables.desktop_breakpoint}px) {
        .prefooter-cta-container {
            > div {
                /* Min height was set to match Figma height. Remove if we don't need this. */
                min-height: 430px;
            }
        }
    }
`;

export default PreFooterCTAStyles;
import React from 'react';
import PropTypes from 'prop-types';
import Container from '../Shared/Container';
import OurServicesStyles, { TextSectionStyles } from './OurServices.styled';
import { Element } from 'react-scroll';
import { extractColors } from '../../utils/utils';
import marked from 'marked';
import ScrollLink from '../Shared/ScrollLink';

function OurServices({
  title,
  text,
  contentSections,
}) {

  marked.setOptions({
    pedantic: false,
    gfm: true,
    tables: true,
    breaks: false,
    sanitize: false,
    smartLists: true,
    smartypants: false,
    xhtml: false,
    closeHtmlTags: true
  });

  const trimmedText = text ? text.trim() : "";

  return (
    <OurServicesStyles>
      <Element name="services">
        <Container>
          <div className="inner">
            <h2 className="title">{title}</h2>

            {
              trimmedText !== "" ? (
                <div className="text intro-text"
                  dangerouslySetInnerHTML={{ __html: marked(trimmedText) }}
                />  
                ) 
              : null
            }

            {contentSections.map((content, idx) => {

                const splitContent = (content.featuredImage && content.imageLocation) ? "split-content" : "full-content";
                const rightSplit = (content.featuredImage && content.imageLocation && content.imageLocation === "Right Side") ? "right-split" : "";
                const backgroundColor = extractColors(content.sectionBackgroundColor);

                return (
                  <TextSectionStyles 
                    key={`content-${idx}`} 
                    className={`cta-section ${splitContent} ${rightSplit}`}
                    backgroundColor={(backgroundColor[0] !== "#000000") ? backgroundColor[0] : "transparent"}
                  >
                    
                    {
                      (content.featuredImage) ? (
                        <div className="image-block">
                          <img src={content.featuredImage.file.url + "?q=50"} alt={content.featuredImage.title} />
                        </div>  
                      ) : null
                    }

                    <div className="text-block">
                      {content.sectionHeader !== null? 
                        (
                          <h3 className="content-title">{content.sectionHeader}</h3>
                        ) : null
                      }


                      <div className="text">
                        <div
                          dangerouslySetInnerHTML={{ __html: marked(content.sectionContent.trim()) }}
                        />
                        {content.sectionLink ? 
                        (
                          <div className="section-link-block">

                            <ScrollLink 
                              className="section-link" 
                              to={content.sectionLink}
                              useScroll={content.sectionLink.indexOf("http") === -1}
                              target={content.sectionLink.indexOf("http") === -1 ? "_self" : "_blank"}
                              tabIndex="0"
                            >
                              {content.sectionLinkText}
                            </ScrollLink>
                          </div>
                        ) : null
                        }

                        </div>

                    </div>
                  </TextSectionStyles>
                );
              })
            }

          </div>
        </Container>
      </Element>
    </OurServicesStyles>
  );
}

OurServices.propTypes = {
  title: PropTypes.string,
  text: PropTypes.string,
  splitContent: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string,
      text: PropTypes.string,
      image: PropTypes.shape({
        src: PropTypes.string,
        alt: PropTypes.string,
      }),
    })
  ),
};

export default OurServices;

import styled from 'styled-components';
import Colors from 'pubweb-smokey/dist/colors';


const OurStoryStyles = styled.div`
  margin: 1.5rem 0;
  .inner {
    padding: 25px;
    text-align: center;
    .title {
      margin: 0 0 24px;
      font-size: 48px;
      font-weight: 900;
      font-style: normal;
      line-height: 61px;
      color: ${Colors.primary.black.standard};
    }
    .text {
      line-height: 1.5px;
      color: #000;
      
      p{
        font-family: "acumin-pro", sans-serif;
        font-weight: 400;
        font-size: 18px;
      }
    }
    .cta-button {
      display: inline-block;
      margin-top: 20px;
      margin-bottom: 70px;
      padding: 15px 30px;
      width: 85px;
      font-family: "acumin-pro", sans-serif;
      cursor: pointer;
      background-color: ${Colors.primary.claytonBlue.standard};
      text-decoration: none;
      color: white;
      transition: all 400ms ease-in-out;
      &:hover {
        background-color: ${Colors.primary.claytonBlue.standard};
      }
    }
    .divider {
      display: block;
      max-width: 460px;
    }
  }
`;

export default OurStoryStyles;

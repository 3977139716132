import React, { useState } from 'react';
import { Link } from 'react-router-dom';

import XSvg from 'pubweb-smokey/dist/images/svg/iconography-16x16/x.svg'
import { getSlug } from '../../../utils/utils';

import Markdown from '../Markdown/Markdown';

import { AlertStyles } from './Alert.styled';


// Added internalLink bool to override use of <Link> when alerts are used with a site like Studio.
// You get and error when <Link> is used from Studio (refer to the usage of Header/Footer components in Studio which is similar).
// Added linkPrefix to allow a site like Newsroom to pass a prefix (e.g. /newsroom) that could be added to the link URL for a Contentful sourced alert page.
const Alert = ({alert, onClose, bannerColor, calloutColor, internalLink = true, linkPrefix = ""}) => {
    const [ bannerClosed, setBannerClosed ] = useState(false);

    const closeBanner = (id) => {
        setBannerClosed(true);
        if (onClose) {
            onClose(id);
        }
    }

    const renderPageLink = (linkUrl, linkText, internalLink = true) => {

        // Added internalLink bool to override use of <Link> when alerts are used with a site like Studio.
        // You get and error when <Link> is used from Studio (refer to the usage of Header/Footer components in Studio which is similar).
        // Per request added target _blank to make links open in new tab.
        const pageLink = <a target="_blank" href={linkUrl} className="banner-link caption gtm-homepage-banner">
              {linkText}
            </a> 

        return (pageLink);
    }


    // There are two color options for the alert banner.
    // If the alert is a contenfully sourced page, route the page to "/[optional prefix][the contentful alert page link (see note below)]".
    //      Note: the pageLink for an alert page sourced in contentful is expected to be "/alerts/[anything else you want]".
    //      This pageLink will be used as a key to retrieve the contentful data so it needs to match what contentful has.
    //      Therefore, a trailing / should be left off the page link in contentful because this does not come across from the routing
    //      and you can't tell if it existed or not until the contentful request fails.
    // If the link is clicked, the alert page will use the alert id to load the page information from contentful.
    return (
        <AlertStyles
            bannerColor = {bannerColor}
            calloutColor = {calloutColor}
        >
            {alert && !bannerClosed &&
            <div className="banner-container">
                <div className="banner-items">
                    {alert.bannerCallout && <p className="banner-callout label" >{alert.bannerCallout}</p>}
                    {alert.bannerText && <div className="banner-text caption"> <Markdown markdown={alert.bannerText} /> </div>}
                    {alert.contentfulSourcedPage ?
                        alert.bannerLinkText && alert.pageLink &&
                        <Link target="_blank" to={`${linkPrefix}${alert.pageLink}`} className="banner-link caption gtm-homepage-banner">
                            {alert.bannerLinkText}
                        </Link>
                        :
                        alert.pageLink && alert.bannerLinkText && renderPageLink(alert.pageLink, alert.bannerLinkText, internalLink)
                    }
                </div>
                <img
                    className="close-banner"
                    tabIndex='0'
                    src={XSvg}
                    onClick={() => closeBanner(alert.contentfulPageName)}
                    onKeyDown={(e) => {
                        if(e.keyCode === 13){
                            closeBanner(alert.contentfulPageName);
                        }
                    }}
                    alt="close banner"
                    width="20" height="20"
                />
            </div>
            }
        </AlertStyles>
    );
  }

export default Alert;

import React from 'react';
import PropTypes from 'prop-types';
import Colors from 'pubweb-smokey/dist/colors';
import Button from 'pubweb-smokey/dist/components/Buttons/Button';

import { CTAStyles } from './CTA.styled';
import ScrollLink from '../ScrollLink';

//Note: This CTA features custom edits from Clayton Connect. If you're wanting the standard CTA experience, please look at CH.com
export const convertButtonColor = (hexCode) => {
    let color;
    
    // Check these or the toLowerCase will give an exception.
    if (!hexCode || !hexCode[0] || !hexCode[1]) {
        // What to do if no color defined? Could leave color as 'undefined' as well.
        return Colors.primary.claytonBlue;
    }

    switch(( hexCode[0].toLowerCase() || "#ffffff")) {
        case "#0075c9":
            color = Colors.primary.claytonBlue;
            break;
        case "#352269":
            color = Colors.secondary.summerNight;
            break;
        case "#00b0ac":
            color = Colors.secondary.kiddiePool;
            break;
        case "#ef4b5d":
            color = Colors.accent.ladyBug;
            break;
        case "#004b8d":
            color = Colors.accent.darkBlue;
            break;

        case "#b21f28":
            color = Colors.accent.barnDoor;
            break;

        case "#00000000":
            color = Colors.secondary.transparent;
            break;
        default:
            color = Colors.primary.claytonBlue;
      }

      if(hexCode[0].toLowerCase() === "#ffffff"){
        switch(hexCode[1].toLowerCase()) {
            case "#0075c9":
                color = Colors.primary.claytonBlue;
                break;
            case "#352269":
                color = Colors.secondary.summerNight;
                break;
            case "#00b0ac":
                color = Colors.secondary.kiddiePool;
                break;
            case "#ef4b5d":
                color = Colors.accent.ladyBug;
                break;
            case "#004b8d":
                color = Colors.accent.darkBlue;
                break;
            case "#b21f28":
                color = Colors.accent.barnDoor;
                break;
            case "#00000000":
                color = Colors.secondary.transparent;
                break;
            default:
                color = Colors.primary.claytonBlue;
          }
      }
      return color;
}

export const determineTextColor = (backgroundColorHexCode) => {
    let color;
    switch(backgroundColorHexCode[0].toLowerCase()) {
        case "#00000000":
            color = Colors.secondary.transparent;
            break;
        case "#f8f8f8":
            color = Colors.primary.black.standard;
            break;
        case "#e5f5ff":
            color = Colors.primary.black.standard;
            break;
        case "#E5F5FF":
            color = Colors.primary.black.standard;
            break;
        case "#ffffff":
            color = Colors.primary.black.standard;
            break;
        case undefined: 
            color = Colors.primary.black.standard;
            break;
        default:
            color = "#ffffff";
    }
    return color;
}

export const reverseColors = (hexCode) => {
    //returns true or false
    //if true, reverse colors by passing in {true} to the buttons onDarkBg prop
    
    // Check these or the toLowerCase will give an exception.
    if (!hexCode || !hexCode[0] || !hexCode[1]) {
        return false;
    }

    //if the button is transparent (#00000000), onDarkBG is always false
    if(hexCode[0] === "#00000000" || hexCode[1] === "#00000000") {
        return false;
    }
    //if color[0] is #ffffff, onDarkBG is true (i.e. white button, with colored text)
    if(hexCode[0].toLowerCase() === "#ffffff") {
        return true;
    }

    return false;
}

export const getImageAlignment = (contentfulString) => {
    let imageAlignment;

    switch(contentfulString) {
        case "Right Side":
            imageAlignment = "right";
            break;
        case "Left Side":
            imageAlignment = "left";
            break;
        case "Center":
            imageAlignment = "center";
            break;
        default:
            imageAlignment = "right";
    }
    return imageAlignment;
}

export const getContentAlignment = (contentfulString) => {
    let contentAlignment;
    switch(contentfulString) {
        case "Right Align":
            contentAlignment = "right";
            break;
        case "Left Align":
            contentAlignment = "left";
            break;
        case "Center Align":
            contentAlignment = "center";
            break;
        default:
            contentAlignment = "right";
    }
    return contentAlignment;
}

// adds a custom class to the CTA button based off of the button text so that we have a class on there to target for GTM triggers.
export const getClassName = (buttonText) => {
    let className = buttonText;
    className = className.replace(/\s+/g, '-').toLowerCase() + ' cta-button';

    return className;
}

// This determines the flex alignment for the button.
export const getButtonAlignment = (buttonAlign, contentAlign) => {
    const contentAlignment = contentAlign ? getContentAlignment(contentAlign) : undefined;
    let buttonAlignment = "flex-start";

    if (buttonAlign) {
        if (buttonAlign === "right") {
            buttonAlignment = "flex-end";
        } else if (contentAlignment) {
            switch(contentAlignment) {
                case "right":
                    buttonAlignment = "flex-end";
                    break;
                case "center":
                    buttonAlignment = "center";
                    break;
                case "left":
                default:
                    buttonAlignment = "flex-start";
            }
        }
    }
    return buttonAlignment;
}

const CTA = ({
        isPreFooterCTA = false,
        buttonLocation,
        backgroundColor,
        buttonColors,
        secondButtonColors,  
        image, 
        imageAlign,
        contentAlign,
        headingText,
        subHeadingText,
        ctaText, 
        customCategory,
        tag,
        buttonText, 
        buttonUrl, 
        buttonAutomationId,
        buttonOpensToNewTab,
        button2Text,
        button2Url,
        button2AutomationId,
        button2OpensToNewTab,
    }) => {

        //Button Location Options
        let buttonAlign = "below";

        if(buttonLocation) {
            if(buttonLocation === "Linked Card-No Button") {
                buttonAlign = "no-button-linked-card"
            }
            if(buttonLocation === "Right Side of Text") {
                buttonAlign = "right"
            }
        }

        const buttonOnDarkBg = buttonColors && reverseColors(buttonColors);
        const btnColor = buttonColors && convertButtonColor(buttonColors);
        const outlineButton = btnColor && ((backgroundColor && backgroundColor[0].toLowerCase() === btnColor.standard) || btnColor === Colors.secondary.transparent);

        // If url starts with # we assume a ScrollLink is being requested to an anchor name string on the page.
        const buttonScrollToSection = (buttonUrl && buttonUrl.indexOf("#") === 0);
        const button2ScrollToSection = (button2Url && button2Url.indexOf("#") === 0);
        // Since the Full Content Control (FCC) is a single page. We will assume any url that is not an anchor link will open on a new page.
        // Note: This logic may change depending on future FCC page requirements for opening on a new tab.
        buttonOpensToNewTab = buttonUrl && !buttonScrollToSection;
        button2OpensToNewTab = button2Url && !button2ScrollToSection;

        //Note: if ScrollLink take out navigateurl
        let button1 = buttonText && buttonUrl && (
            <Button  
                 className={`cta-button-1 ${getClassName(buttonText)} ${outlineButton ? "cta-button-outline" : ""}`}
                 primaryColor={btnColor}
                 buttonStyle={btnColor === Colors.primary.white ? "outlined" : null}
                 onDarkBg={buttonOnDarkBg}
                 automationId={buttonAutomationId}
                 target={buttonOpensToNewTab ? "_blank" : "_self"}
                 navigateUrl={(buttonScrollToSection) ? null : buttonUrl }>
                    {buttonText}
             </Button>
         );

        let button2 = button2Text && button2Url && (
                <Button  
                    className={`cta-button-2 ${getClassName(button2Text)} ${outlineButton ? "cta-button-outline" : ""}`}
                    primaryColor={btnColor}
                    buttonStyle={btnColor === Colors.primary.white || secondButtonColors === "Inverse Colors (default)" ? "outlined" : null}
                    onDarkBg={secondButtonColors === "Match first color" ? buttonOnDarkBg : !buttonOnDarkBg}
                    automationId={button2AutomationId}
                    target={button2OpensToNewTab ? "_blank" : "_self"}
                    navigateUrl={(button2ScrollToSection) ? null : button2Url }
                >
                    {button2Text}
                </Button>
        )

        if (buttonScrollToSection){
            button1 = (
                <ScrollLink
                    className="cta-scroll-button"
                    to={buttonUrl.substring(1)}>
                    {button1}
                </ScrollLink>
            );
        }

        if (button2ScrollToSection){
            button2 = (
                <ScrollLink
                    className="cta-scroll-button"
                    to={button2Url.substring(1)}>
                    {button2}
                </ScrollLink>
            )
        }

        return (
            <CTAStyles 
                backgroundImageAlign={imageAlign && getImageAlignment(imageAlign)}
                buttonAlignment={getButtonAlignment(buttonAlign, contentAlign)}
                isPreFooterCTA={isPreFooterCTA}
                backgroundColor={backgroundColor && backgroundColor} 
                backgroundImage={image}
                contentAlignment={contentAlign && getContentAlignment(contentAlign)}
                textColor={backgroundColor && determineTextColor(backgroundColor)}
                onClick={() => {
                    if(buttonAlign === "no-button-linked-card") {
                        window.location = buttonUrl;
                    }
                }}
                className={buttonAlign === "no-button-linked-card" ? "cta linked-card" : "cta" }
                borderColor={btnColor && btnColor.borderColor ? btnColor.borderColor
                    : btnColor && btnColor.textColor ? btnColor.textColor : Colors.primary.white.standard}
            >

                <div className="cta-inside" 
                    tabIndex="-1"
                    role={buttonAlign === "no-button-linked-card" ? "button" : ""}>
                    {customCategory && <p className="label large custom-category">{customCategory} {tag && <span className="label large tag"> | {tag}</span>} </p>}
                    
                    {headingText && <p className={isPreFooterCTA ? "h4 cta-heading cta-heading-prefooter" : "h4 cta-heading"}>{headingText}</p>}
                    {subHeadingText && <p className={isPreFooterCTA ? "h5 cta-heading cta-heading-prefooter" : "h5 cta-heading"}>{subHeadingText}</p>}
                    {ctaText && <p className={isPreFooterCTA ? "cta-text cta-text-prefooter" : "cta-text"}>{ctaText}</p>}
                    
                    {buttonAlign !== "no-button-linked-card" && 
                        <div className="cta-buttons">
                            {button1}
                            {button2}
                        </div>}
                </div>
            </CTAStyles>
        );
    }

CTA.propTypes = {
    isPreFooterCTA: PropTypes.bool,
    buttonLocation: PropTypes.string,
    backgroundColor: PropTypes.array,
    buttonColors: PropTypes.array,
    image: PropTypes.string,
    imageAlign: PropTypes.string,
    contentAlign: PropTypes.string,
    headingText: PropTypes.string,
    subHeadingText: PropTypes.string,
    ctaText: PropTypes.string,
    buttonText: PropTypes.string,
    buttonUrl: PropTypes.string,
    buttonAutomationId: PropTypes.string,
    buttonOpensToNewTab: PropTypes.bool,
    button2Text: PropTypes.string,
    button2Url: PropTypes.string,
    button2AutomationId: PropTypes.string,
    button2OpensToNewTab: PropTypes.bool,
};

export default CTA;

import styled from 'styled-components';
import * as variables from 'pubweb-smokey/dist/components/GridSystem/_vars_widths.js';
import Colors from 'pubweb-smokey/dist/colors';

const HeroVideoStyles = styled.div`
  .inner {
    position: relative;
    height: 416px;
    width: 100%;
    overflow: hidden;


    video{
      width: 225%;
      height: 100%;
      object-fit: cover;
    }
    

    .hero-image-desktop, .hero-image-mobile{
      width: 100%;
      height: 100%;

      img {
        height: 100%;
        width: 100%;
        object-fit: cover;
      }
    }

    .hero-image-desktop{
      display: block;
    }
    .hero-image-mobile{
      display: block;
    }

    .wistia_embed, .hero-image{
      height: 416px;
      position: absolute;
      object-fit: cover;
    }

    .overlay {
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
      background-color: ${Colors.primary.claytonBlue.standard};
      opacity: 65%;
      z-index: 1;

    }

    .text-wrapper {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      display: flex;
      align-items: center;
      z-index: 2;
      }
    }

    .cta-container{
      padding: 0 20px;
    }

    .cta-heading{
      color: #fff;
      font-size: 22px;
      font-weight: 700;
      font-style: normal;
      line-height: 35px;
    }

    .h5{
      font-size: 22px;      
    }

    .cta-buttons{
      margin-top: 40px;
    }

    .cta-button{
      text-transform: none;
      font-family: 'acumin-pro', sans-serif;
      font-weight: 400;
      line-height: 27px;
    }
  
    @media only screen and (min-width: 400px){
      .inner{
        video, .wistia_embed{
          width: 175%;
          height: 590px;
        }
        .wistia_responsive_padding {
          /* width: 175%; */
          left: 0;
        }
        .cta-button{
          font-size: 18px;
        }
      }
    }

    @media only screen and (min-width: 400px){
      .inner{
        .cta-heading{
          font-size: 28px;
        }
      }
    }

    @media only screen and (min-width: ${variables.desktop_breakpoint_extended}px){
      .inner{
        height: 590px;

        .hero-image-desktop{
          display: block;
        }
        .hero-image-mobile{
          display: none;
        }

        video, .wistia_embed{
          width: 100%;
          left: 0;
        }
      }

      .inner{
        .overlay{
          right: 50%;
        }
        .cta-container{
          padding: 0;
          width: 60%;
          padding-left: 20px;
        }
        .cta-heading{
          font-size: 34px;
          line-height: 40px;
        }
        .h5{
          font-size: 28px;      
        }
      }
    }


`;

export default HeroVideoStyles;

import React from 'react';
import { Helmet } from "react-helmet";
import Typography from "pubweb-smokey/dist/components/Typography/Typography";
import Footer from './components/Footer/Footer';
import Header from './components/Header/Header';
import Layout from './components/Layout/Layout';
import HeroVideo from './components/HeroVideo/HeroVideo';
import OurStory from './components/OurStory/OurStory';
import OurServices from './components/OurServices/OurServices';
import OurDetails from './components/OurDetails/OurDetails';
import WebsiteBanner from "./components/Shared/WebsiteBanner/WebsiteBanner";
import claytonLogo from './components/Shared/images/clayton-logo.jpg';
import PreFooterCTA from './components/PreFooterCTA/PreFooterCTA';
import { getSiteUrl } from './utils/config';
import { getFullPageControlContent } from './services/contentfulService';
import { CH_ALERTS_CLOSED_KEY, CH_CONNECT_WEBSITE_BANNER_CONTENTFUL_KEY } from './utils/config';

import './styles/fonts.css';

const contentfulKey = '12HaPN3zT4PXoy7Qvscmh6';    //Clayton Connect - FCC Page

const App = ({data}) => {
       
    const ogImage = (data.ogImage) ? data.ogImage.file.url : getSiteUrl(claytonLogo); 

    return (
            <Typography>
                <Layout>
                    
                    <Helmet
                        htmlAttributes={{ lang: 'en' }}
                    >        
                        <title>{data.titleTag}</title>
                        <meta name="description" content={data.metaDescription} />
                        <meta httpEquiv="X-UA-Compatible" content="IE=edge" />
                        <meta property="og:image" content={ogImage} />
                        <meta name="viewport" content="width=device-width, initial-scale=1, maximum-scale=5" />
                        <meta charSet="utf-8" />
                        {/* <!-- Osano Privacy Script --> */}
                        <script src="https://cmp.osano.com/AzZd2RTbLigwQ5KJW/fc5c932e-d137-4fcf-ba8f-d9531da1e2ef/osano.js"></script>
                        {/* <!-- End Osano Privacy Script --> */}
                    </Helmet>

                    <WebsiteBanner 
                        contentfulKey={CH_CONNECT_WEBSITE_BANNER_CONTENTFUL_KEY} 
                        localStorageKey={CH_ALERTS_CLOSED_KEY} 
                    />

                    <Header />

                    <HeroVideo 
                        cta={data.heroCTA}    
                        heroImage={data.heroImage}   
                        mobileHeroImage={data.mobileHeroImage}             
                    />


                    <OurStory 
                        title={data.overviewHeader}
                        text={data.overview}
                        buttonLink={data.overviewLink}
                        buttonText={data.overviewLinkText}
                    />
                    
                    <OurServices 
                        title={data.mainContentHeader}
                        text={data.mainContentIntro}
                        contentSections={data.mainContentSections}
                    />

                    {data.contactInfo && (
                        <OurDetails 
                            contactInfo={data.contactInfo}
                        />
                    )}

                    {data.preFooterCTA && <PreFooterCTA preFooterCTA={data.preFooterCTA} />}

                    <Footer />

                </Layout>
            </Typography>
        );
 }

App.getInitialProps = async ({ req, res, match, history, location, ...ctx })  => {

    let data = null;

    try{

        await getFullPageControlContent(contentfulKey)
        .then((result) => {
           data = result;
        });


    }catch(error){
        console.log(error);
    }

    return {
        data
    };  
};

export default App;

import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Element } from 'react-scroll';
import CTA from '../Shared/CTA/CTA';

import HeroVideoStyles from './HeroVideo.styled';
import heroVideo from '../../media/connect-video-bg.mp4';
import { extractColors, convertButtonColor, reverseColors } from '../../utils/utils';

function HeroVideo({
  cta,
  heroImage,
  mobileHeroImage
}) {

  //Note that this method of using video from wistia does NOT like being resized. Standard devices when load should be fine though.
  const heroImageUsed = heroImage && heroImage[0];
  const mobileHeroImageUsed = mobileHeroImage && mobileHeroImage[0];

  //image or video
  let heroImageRendered = null;
  let mobileHeroImageRendered = null;

  useEffect(() => {

    //Use to disable tabbing to background video
    window.addEventListener('load', () => {
      var checkForWistiaPlayButton = document.getElementsByClassName("w-css-reset w-vulcan-v2-button");

      for(let i = 0; i < checkForWistiaPlayButton.length; i++){
        checkForWistiaPlayButton[i].tabIndex = -1;
      }

    });


  }, [])

  const heroImageScript = (heroImageUsed.mediaType === "Video" || mobileHeroImageUsed.mediaType === "Video") ? 
  (
    <script src="https://fast.wistia.com/assets/external/E-v1.js" async></script>
  ) : null;

  //Make sure to hide desktop until breakpoint.
  if (heroImageUsed){
    heroImageRendered = (heroImageUsed.mediaType === "Video") ? (
      <>
        <div tabIndex="-1" className={`wistia_embed wistia_async_${heroImageUsed.embeddedContent} hero-image-desktop videoFoam=false fakeFullscreen=true autoPlay=true silentAutoPlay=allow muted=true endVideoBehavior=loop playbar=false playButton=false smallPlayButton=false volumeControl=false settingsControl=false share=false fullscreenButton=false fitStrategy=cover playSuspendedOffScreen=false googleAnalytics=false`} suppressHydrationWarning={true}></div>
      </>
    ) 
    : 
      (heroImageUsed.mediaType === "Image") ? (
        <div className="hero-image-desktop">
          <img src={heroImageUsed.embeddedImage.file.url} />
        </div>
      ) : null;
  }

  if (mobileHeroImageUsed){
    mobileHeroImageRendered = (mobileHeroImageUsed.mediaType === "Video") ? (
      <>
        <div tabIndex="-1" className={`wistia_embed wistia_async_${mobileHeroImageUsed.embeddedContent} hero-image-mobile videoFoam=false fakeFullscreen=true autoPlay=true silentAutoPlay=allow muted=true endVideoBehavior=loop playbar=false playButton=false smallPlayButton=false volumeControl=false settingsControl=false share=false fullscreenButton=false fitStrategy=cover playSuspendedOffScreen=false googleAnalytics=false`} suppressHydrationWarning={true}></div>
      </>
    ) 
    : 
      (mobileHeroImageUsed.mediaType === "Image") ? (
        <div className="hero-image-mobile">
          <img src={mobileHeroImageUsed.embeddedImage.file.url} />
        </div>
      ) : null;
  }


  const heroCTARendered = (cta) ? (

    <div className="cta-container" key={cta.sys.id}>
        <CTA
            buttonLocation={cta.ctaButtonLocation}
            buttonColors={extractColors(cta.buttonAndTextColors)}
            secondButtonColors={cta.secondButtonColor}
            backgroundColor={extractColors(cta.ctaBackgroundColor)}
            image={(cta.ctaImage !== null) ? cta.ctaImage.file.url : null}
            imageAlign={cta.ctaImageLocation}
            contentAlign={cta.alignCtaContent}
            customCategory={cta.ctaCustomCategory}
            tag={cta.ctaTag}
            headingText={cta.ctaHeader}
            subHeadingText={cta.ctaSubHeader}
            ctaText={cta.ctaIntro}
            buttonText={cta.firstCtaButtonText}
            buttonUrl={cta.firstCtaButtonUrl}
            buttonAutomationId="cta-button-1"
            buttonOpensToNewTab={true}
            button2Text={cta.secondCtaButtonText}
            button2Url={cta.secondCtaButtonUrl}
            button2OpensToNewTab={false}
            button2AutomationId="cta-button-2"
        />
    </div>

  ) : null;

  return (
    <HeroVideoStyles>
      <Element name="home">

        <div className="inner">
            
          <div className="overlay" />

          {heroImageScript} 
          {heroImageRendered}
          {mobileHeroImageRendered}

          <div className="text-wrapper">
            {heroCTARendered}
          </div>
        </div>
      </Element>
    </HeroVideoStyles>
  );
}

HeroVideo.propTypes = {
  title: PropTypes.string,
  buttonText: PropTypes.string,
};

export default HeroVideo;
